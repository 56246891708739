const general = {
	init: function () {
		general.languageDropdown();
		general.navbarDropdown();
		general.navbarDropDownNav();
		general.paceLoading();
		general.swiperBannerSlider();
		general.swiperNewsEventSlider();
		general.swiperOurBusinessSlider();
		general.swiperProductDetailSlider();
		general.dataBackgroundImage();
		general.scrollUpBehaviour();
		general.activeServiceHoverItem();
		general.wowAnimation();
		general.setActiveMenuItem();
		general.scrollSectionBehaviour();
		general.toggleNewsEventContent();
		general.setActiveSidebarMenu();
		general.searchBox();
		general.sliderTabAboutUs();
		general.mapOffice();
		general.paralaxScroll();
		general.paralaxScrolls();
		general.paralaxBackground();
		general.validateForm();
		general.slideBtnPhoneMail();
		general.magnifyImage();
		general.rearrangeGSCResults();
		general.rearrangeCheckbox();
		general.contactUsNotification();
		general.openRealCookieBanner();
	},

	searchBox: function () {
		if (jQuery(".search-box-outer").length) {
			jQuery(".search-box-outer").on("click", function () {
				jQuery("body").addClass("search-active");
				jQuery(".search__overlay").addClass("search-overlay-open");
			});
			jQuery(".close-search").on("click", function () {
				jQuery("body").removeClass("search-active");
				jQuery(".search__overlay").removeClass("search-overlay-open");
			});
		}
	},

	navbarDropdown: function () {
		if (!jQuery(".mean-container").length) {
			jQuery(".header-area nav").meanmenu();
		}

		const sidebar_menu_wrp = jQuery(".sidebar-nav-wrp");
		const sidebar_menu = jQuery(".sidebar-nav");

		jQuery(window).on("scroll", function () {
			if (jQuery(this).scrollTop() > 200) {
				sidebar_menu_wrp.addClass("fixed");
				sidebar_menu.addClass("fixed animated fadeInDown");
			} else {
				sidebar_menu_wrp.removeClass("fixed fadeInDown");
				sidebar_menu.removeClass("fixed fadeInDown");
			}
		});
	},

	navbarDropDownNav: function () {
		jQuery(document).on("click", "#openButton", function () {
			jQuery(".offcanvas__info").addClass("info-open");
			jQuery(".offcanvas__overlay").addClass("overlay-open");
		});
		jQuery(document).on("click", "#closeButton", function () {
			jQuery(".offcanvas__info").removeClass("info-open");
			jQuery(".offcanvas__overlay").removeClass("overlay-open");
		});
	},

	paceLoading: function () {
		Pace.options = {
			document: true,
			eventLag: {
				minSamples: 10,
				lagThreshold: 3,
			},
			restartOnRequestAfter: 500,
			elements: {
				selectors: ["body", ".my-element"],
			},
			startOnPageLoad: true,
		};

		let startTime = Date.now();

		Pace.on("done", function () {
			jQuery("#preloader").addClass("isdone");
			jQuery(".loading").addClass("isdone");
		});

		let interval = setInterval(function () {
			if (Date.now() - startTime > 3000) {
				clearInterval(interval);
				Pace.stop();
				jQuery("#preloader").addClass("isdone");
				jQuery(".loading").addClass("isdone");
			}
		}, 100);

		Pace.start();

		document.addEventListener("google-search-results-loaded", function () {
			Pace.stop();
			jQuery("#preloader").addClass("isdone");
			jQuery(".loading").addClass("isdone");
		});
	},

	swiperBannerSlider: function () {
		const sliderActive1 = ".banner__slider";
		const sliderInit1 = new Swiper(sliderActive1, {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 24,
			speed: 800,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: ".banner__arry-next",
				prevEl: ".banner__arry-prev",
			},
			on: {
				init: function () {
					updateSlideCounter(this);
				},
				slideChange: function () {
					updateSlideCounter(this);
				},
			},
		});

		function updateSlideCounter(swiper) {
			const totalSlides = swiper.slides.filter(
				(slide) => !slide.classList.contains("swiper-slide-duplicate")
			).length;
			const currentSlide = swiper.realIndex + 1;
			document.querySelector(
				".slider-counter"
			).textContent = `${currentSlide} / ${totalSlides}`;
		}

		function animated_swiper(selector, init) {
			const animated = function animated() {
				jQuery(selector + " [data-animation]").each(function () {
					const anim = jQuery(this).data("animation");
					const delay = jQuery(this).data("delay");
					const duration = jQuery(this).data("duration");
					jQuery(this)
						.removeClass("anim" + anim)
						.addClass(anim + " animated")
						.css({
							webkitAnimationDelay: delay,
							animationDelay: delay,
							webkitAnimationDuration: duration,
							animationDuration: duration,
						})
						.one("animationend", function () {
							jQuery(this).removeClass(anim + " animated");
						});
				});
			};

			animated();

			init.on("slideChange", function () {
				jQuery(sliderActive1 + " [data-animation]").removeClass("animated");
			});

			init.on("slideChange", animated);

			jQuery(init.params.navigation.nextEl).on("click", function () {
				jQuery(selector + " [data-animation]").finish();
			});
			jQuery(init.params.navigation.prevEl).on("click", function () {
				jQuery(selector + " [data-animation]").finish();
			});
		}

		animated_swiper(sliderActive1, sliderInit1);
	},

	swiperNewsEventSlider: function () {
		const swiper = new Swiper(".news-event__slider", {
			loop: true,
			spaceBetween: 24,
			speed: 800,
			autoplay: {
					delay: 3000,
					disableOnInteraction: false,
			},
			breakpoints: {
					1200: { slidesPerView: 3 },
					992: { slidesPerView: 2 },
					320: { slidesPerView: 1 },
			},
			navigation: {
					nextEl: ".news-event__arry-next",
					prevEl: ".news-event__arry-prev",
			},
			initialSlide: 0,
			observer: true,
			observeParents: true, // Observes changes to parent containers
		});
	},

	swiperProductDetailSlider: function () {
		const swiper = new Swiper(".product-detail__slider", {
			loop: true,
			speed: 300,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: ".product-detail__arry-next",
				prevEl: ".product-detail__arry-prev",
			},
			pagination: {
				el: ".product-detail__dot",
				clickable: true,
			},
			on: {
				init: function () {
					setTimeout(() => {
						const initialSlide =
							swiper.slides[swiper.activeIndex].querySelector(".image p");
						if (initialSlide) {
							const initialFileName = initialSlide.textContent;
							document.querySelector(".file_name_slider").textContent =
								initialFileName;
						}
					}, 0);
				},
				slideChange: function () {
					setTimeout(() => {
						const activeSlide =
							swiper.slides[swiper.activeIndex].querySelector(".image p");
						if (activeSlide) {
							const fileName = activeSlide.textContent;
							document.querySelector(".file_name_slider").textContent =
								fileName;
						}
					}, 0);
				},
			},
		});

		// Initialize Swiper instance
		swiper.init();
	},

	swiperOurBusinessSlider: function () {
		const swiper = new Swiper(".our-business__slider", {
			loop: true,
			speed: 300,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
			},
			breakpoints: {
				1200: {
					slidesPerView: 2,
				},
				992: {
					slidesPerView: 2,
				},
				320: {
					slidesPerView: 1,
				},
			},
			navigation: {
				nextEl: ".our-business__arry-next",
				prevEl: ".our-business__arry-prev",
			},
		});
	},

	dataBackgroundImage: function () {
		jQuery("[data-background").each(function () {
			jQuery(this).css(
				"background-image",
				"url( " + jQuery(this).attr("data-background") + "  )"
			);
		});
	},

	scrollUpBehaviour: function () {
		// Back to top area start here ***
		const $scrollUp = jQuery(".scroll-up");
		const scrollPath = document.querySelector(".scroll-up path");
		const pathLength = scrollPath.getTotalLength();

		scrollPath.style.transition = scrollPath.style.WebkitTransition = "none";
		scrollPath.style.strokeDasharray = pathLength + " " + pathLength;
		scrollPath.style.strokeDashoffset = pathLength;
		scrollPath.getBoundingClientRect(); // Trigger layout to update the path

		scrollPath.style.transition = scrollPath.style.WebkitTransition =
			"stroke-dashoffset 15ms linear";

		const updateScroll = function () {
			const scroll = jQuery(window).scrollTop();
			const height = jQuery(document).height() - jQuery(window).height();
			const offset = pathLength - (scroll * pathLength) / height;
			scrollPath.style.strokeDashoffset = offset;
		};

		updateScroll();
		jQuery(window).on("scroll", updateScroll);

		const offset = 50; // Offset where the scroll-up button appears

		jQuery(window).on("scroll", function () {
			if (jQuery(this).scrollTop() > offset) {
				jQuery(".scroll-up").addClass("active-scroll");
			} else {
				jQuery(".scroll-up").removeClass("active-scroll");
			}
		});

		$scrollUp.on("click", function (event) {
			jQuery("html, body").scrollTop(0);
			return false;
		});
	},

	activeServiceHoverItem: function () {
		jQuery(".service__item").hover(function () {
			jQuery(".service__item").removeClass("active");
			jQuery(this).addClass("active");
		});
	},

	wowAnimation: function () {
		new WOW().init();
	},

	setActiveMenuItem: function () {
		const currentUrl = window.location.href;

		jQuery(".header__main li a, .mean-nav li a").each(function () {
			const linkUrl = jQuery(this).attr("href");

			if (
				currentUrl.includes("/business") ||
				currentUrl.includes("/bisnis-kami")
			) {
				if (
					(linkUrl.includes("/business") || linkUrl.includes("/bisnis-kami")) &&
					currentUrl.includes(linkUrl.split("/").pop())
				) {
					jQuery(this).addClass("active");
					jQuery(this).parents("li").addClass("active");
				}
			} else if (
				currentUrl.includes("/news") ||
				currentUrl.includes("/berita-acara")
			) {
				if (
					(linkUrl.includes("/news") || linkUrl.includes("/berita-acara")) &&
					currentUrl.includes(linkUrl.split("/").pop())
				) {
					jQuery(this).addClass("active");
					jQuery(this).parents("li").addClass("active");
				}
			} else if (
				currentUrl.includes("/recruitment") ||
				currentUrl.includes("/rekrutmen")
			) {
				if (
					(linkUrl.includes("/recruitment") ||
						linkUrl.includes("/rekrutmen")) &&
					currentUrl.includes(linkUrl.split("/").pop())
				) {
					jQuery(this).addClass("active");
					jQuery(this).parents("li").addClass("active");
				}
			} else if (
				currentUrl.includes("/about-us") ||
				currentUrl.includes("/tentang-kami")
			) {
				if (
					(linkUrl.includes("/about-us") ||
						linkUrl.includes("/tentang-kami")) &&
					currentUrl.includes(linkUrl.split("/").pop())
				) {
					jQuery(this).addClass("active");
					jQuery(this).parents("li").addClass("active");
				}
			} else if (
				currentUrl.includes("/contact-us") ||
				currentUrl.includes("/hubungi-kami")
			) {
				if (
					(linkUrl.includes("/contact-us") ||
						linkUrl.includes("/hubungi-kami")) &&
					currentUrl.includes(linkUrl.split("/").pop())
				) {
					jQuery(this).addClass("active");
					jQuery(this).parents("li").addClass("active");
				}
			}
		});
	},

	setActiveSidebarMenu: function () {
		const currentUrl = window.location.href;

		jQuery(".sidebar-item li a").each(function () {
			const linkUrl = jQuery(this).attr("href");

			if (currentUrl === linkUrl) {
				jQuery(this).addClass("active");
				jQuery(this).parents("li a").addClass("active");
			}
		});
	},

	sliderTabAboutUs: function () {
		const swiper = new Swiper(".corporate-slider", {
			loop: false,
			spaceBetween: 16,
			speed: 300,
			allowSlideNext: true,
			allowSlidePrev: true,
			breakpoints: {
				1200: {
					slidesPerView: 3,
				},
				992: {
					slidesPerView: 2,
				},
				320: {
					slidesPerView: 1,
				},
			},
			navigation: {
				nextEl: ".corporate-history-next",
				prevEl: ".corporate-history-prev",
			},
			on: {
				init: function () {
					this.update();
				},
				slideChange: function () {
					this.update();
				},
			},
		});
	},

	toggleNewsEventContent: function () {
		jQuery(".sidebar-nav-btn-collapse")
			.off("click")
			.on("click", function (event) {
				event.preventDefault();
				const $content = jQuery(this)
					.closest(".sidebar-nav-wrp ")
					.find(".sidebar-content");
				const $icon = jQuery(this).find("i");

				$content.stop(true, true).slideToggle(300);
				$icon.toggleClass("rotated");
			});
	},

	scrollSectionBehaviour: function () {
		const fixed_top = jQuery(".header-area");
		const headerHeight = fixed_top.outerHeight();
		const extraOffset = 140;

		// Check if the browser supports `scroll-behavior: smooth`
		const supportsNativeSmoothScroll =
			"scrollBehavior" in document.documentElement.style;

		function smoothScroll(targetPosition) {
			const startPosition = window.pageYOffset;
			const distance = targetPosition - startPosition;
			const duration = 800;
			let startTime = null;

			function animation(currentTime) {
				if (startTime === null) startTime = currentTime;
				const timeElapsed = currentTime - startTime;
				const run = ease(timeElapsed, startPosition, distance, duration);
				window.scrollTo(0, run);
				if (timeElapsed < duration) requestAnimationFrame(animation);
			}

			function ease(t, b, c, d) {
				t /= d / 2;
				if (t < 1) return (c / 2) * t * t + b;
				t--;
				return (-c / 2) * (t * (t - 2) - 1) + b;
			}

			requestAnimationFrame(animation);
		}

		jQuery('a.scroll-down, a[href^="#"]').on("click", function (event) {
			const targetId = jQuery(this).attr("href").substring(1);
			const targetElement = jQuery("#" + targetId);

			if (targetElement.length) {
				event.preventDefault();
				const targetPosition =
					targetElement.offset().top - headerHeight - extraOffset;

				if (supportsNativeSmoothScroll) {
					// Use native smooth scroll if supported
					window.scrollTo({
						top: targetPosition,
						behavior: "smooth",
					});
				} else {
					// Fallback to custom smooth scroll
					smoothScroll(targetPosition);
				}
			}
		});
	},

	mapOffice: function () {
		jQuery(document).ready(function ($) {
			let mapEl = $("#map");

			if (mapEl.length) {
				if (mapEl.data("mapInstance")) {
						mapEl.data("mapInstance").remove();
						mapEl.removeData("mapInstance");
				}

				var map = L.map("map", {
						scrollWheelZoom: false,
				});

				mapEl.data("mapInstance", map);

				L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
					attribution:
						'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				}).addTo(map);

				var markers = [];
				var popUp = [];
				if (mapEl[0].getAttribute("lang") == "en_US") {
					popUp = [
						"<div><b>Office Jakarta</b><br></div>" +
							"<div>Jalan Cikini Raya No. 95 RT.10/RW.4<br></div>" +
							"<div>CCM Building, 5th Floors<br></div>" +
							"<div>Menteng - Jakarta Pusat (10330)<br></div>" +
							'<div><a href="https://maps.app.goo.gl/V7s1SNciBdkiBo13A" target="_blank">View on Google Maps</a></div>',
						"<div><b>Office Surabaya</b><br></div>" +
							"<div>Ruko Pasar Modern Puncak Permai No.101-102<br></div>" +
							"<div>Jl. Raya Darmo Permai III, Surabaya 60226 <br></div>" +
							'<div><a href="https://maps.app.goo.gl/Rdhv6qiYWJNAeZcW6" target="_blank">View on Google Maps</a></div>',
						"<div><b>Office Cilegon</b><br></div>" +
							"<div>Jl. Raya Anyer (Simpang Tiga) No. 63,<br></div>" +
							"<div>Cilegon-Banten 42431, Indonesia <br></div>" +
							'<div><a href="https://maps.app.goo.gl/geb1qR6vzFwWVpRq6" target="_blank">View on Google Maps</a></div>',
						"<div><b>Office Medan</b><br></div>" +
							"<div>Forum Nine Building, 9th Floor, Room No: 923,<br></div>" +
							"<div>Jl. Imam Bonjol No. 9, Medan 20112, Indonesia <br></div>" +
							'<div><a href="https://maps.app.goo.gl/kohgT2JXxzrJXXfo8" target="_blank">View on Google Maps</a></div>',
					];
				} else {
					popUp = [
						"<div></div><b>Kantor Jakarta</b></div>" +
							"<div>Jalan Cikini Raya No. 95 RT. 10/RW .4<br></div>" +
							"<div>Gedung CCM, Lantai 5<br></div>" +
							"<div>Menteng - Jakarta Pusat (10330)<br></div>" +
							'<div><a href="https://maps.app.goo.gl/V7s1SNciBdkiBo13A" target="_blank">Lihat di Google Map</a></div>',
						"<div><b>Kantor Surabaya</b><br></div>" +
							"<div>Ruko Pasar Modern Puncak Permai No. 101-102<br></div>" +
							"<div>Jl. Raya Darmo Permai III, Surabaya 60226 <br></div>" +
							'<div><a href="https://maps.app.goo.gl/Rdhv6qiYWJNAeZcW6" target="_blank">Lihat di Google Map</a>',
						"<div><b>Kantor Cilegon</b><br></div>" +
							"<div>Jl. Raya Anyer (Simpang Tiga) No. 63,<br></div>" +
							"<div>Cilegon-Banten 42431, Indonesia <br></div>" +
							'<div><a href="https://maps.app.goo.gl/geb1qR6vzFwWVpRq6" target="_blank">Lihat di Google Map</a></div>',
						"<div><b>Kantor Medan</b><br></div>" +
							"<div>Gedung Forum Nine, Lantai 9 No. 923,<br></div>" +
							"<div>Jl. Imam Bonjol No. 9, Medan 20112, Indonesia <br></div>" +
							'<div><a href="https://maps.app.goo.gl/kohgT2JXxzrJXXfo8" target="_blank">Lihat di Google Map</a></div>',
					];
				}

				// Tambahkan marker untuk Jakarta
				var jakartaMarker = L.marker([-6.194262172698246, 106.83976190309674])
					.addTo(map)
					.bindPopup(popUp[0]);
				markers.push(jakartaMarker.getLatLng());

				// Tambahkan marker untuk Surabaya
				var surabayaMarker = L.marker([-7.277096563258523, 112.69135911735118])
					.addTo(map)
					.bindPopup(popUp[1]);
				markers.push(surabayaMarker.getLatLng());

				// Tambahkan marker untuk Cilegon
				var cilegonMarker = L.marker([-6.009322176698895, 106.02917338095179])
					.addTo(map)
					.bindPopup(popUp[2]);
				markers.push(cilegonMarker.getLatLng());

				// Tambahkan marker untuk Medan
				var medanMarker = L.marker([3.586624890771388, 98.67417503799629])
					.addTo(map)
					.bindPopup(popUp[3]);
				markers.push(medanMarker.getLatLng());

				// Mengatur peta agar mencakup semua marker yang ada
				map.fitBounds(markers, {
					maxZoom: 5, // Batas zoom maksimum
				});

				// Membuka popup Jakarta secara otomatis setelah peta diatur
				jakartaMarker.openPopup();
			}
		});
	},

	paralaxScroll: function () {
		window.addEventListener("scroll", function () {
			var parallaxBg = document.getElementById("parallax-bg");
			var scrollPosition = window.scrollY;
			var maxHeight = 480;
			var minHeight = 0;

			if (scrollPosition > maxHeight) {
				scrollPosition = maxHeight;
			}

			var newHeight = maxHeight - scrollPosition;

			if (newHeight < minHeight) {
				newHeight = minHeight;
			}
			if (parallaxBg) {
				parallaxBg.style.height = newHeight + "px";
			}
		});
	},

	paralaxBackground: function () {
		window.addEventListener("scroll", function () {
			var parallaxBg = document.getElementById("paralax-background");
			var parallaxBgSubCategory = document.getElementById(
				"paralax-background-subcategory"
			);
			var scrollPosition = window.scrollY;
			var maxHeight = 430;
			var maxHeightSubCategory = 400;
			var minHeight = 0;

			if (scrollPosition > maxHeight) {
				scrollPosition = maxHeight;
				scrollPosition = maxHeightSubCategory;
			}

			var newHeight = maxHeight - scrollPosition;
			var newHeightSubCategory = maxHeightSubCategory - scrollPosition;

			if (newHeight < minHeight) {
				newHeight = minHeight;
			}
			if (newHeightSubCategory < minHeight) {
				newHeightSubCategory = minHeight;
			}
			if (parallaxBg) {
				parallaxBg.style.height = newHeight + "px";
			}
			if (parallaxBgSubCategory) {
				parallaxBgSubCategory.style.height = newHeightSubCategory + "px";
			}
		});
	},

	paralaxScrolls: function () {
		window.addEventListener("scroll", function () {
			var parallaxBg = document.getElementById("parallax-about");
			var scrollPosition = window.scrollY;
			var maxHeight = 400;
			var minHeight = 0;

			var newHeight = maxHeight - scrollPosition;

			if (newHeight < minHeight) {
				newHeight = minHeight;
			}
			if (parallaxBg) {
				parallaxBg.style.height = newHeight + "px";
			}
		});
	},

	validateForm: function () {
		const submitButton = document.querySelector(".wpcf7-submit"); // Pilih tombol submit
		const alertElement = document.querySelector(".alert"); // Pilih elemen alert
		const currentUrl = window.location.href;
		const isIndonesian = currentUrl.includes("/kontak-kami/");
		if (submitButton) {
			submitButton.addEventListener(
				"click",
				function (event) {
					var nameField = document.querySelector('input[name="your-name"]');
					var emailField = document.querySelector('input[name="your-email"]');
					var phoneField = document.querySelector(
						'input[name="your-phonenumber"]'
					);
					var companyField = document.querySelector(
						'input[name="your-company"]'
					);
					var messageField = document.querySelector(
						'textarea[name="your-message"]'
					); // Ganti ke textarea untuk pesan
					var checkField = document.querySelector(
						'input[name="checkbox-281[]"]'
					);
					var isValid = true;
					// Reset state error sebelumnya
					document
						.querySelectorAll(".wpcf7-not-valid-tip")
						.forEach(function (el) {
							el.remove();
						});
					// Cek setiap field dan tambah
					if (!nameField.value.trim()) {
						isIndonesian
							? showError(nameField, "Nama diperlukan")
							: showError(nameField, "Name is required");
						isValid = false;
					}
					if (!emailField.value.trim()) {
						isIndonesian
							? showError(emailField, "Email diperlukan")
							: showError(emailField, "Email is required");
						isValid = false;
					}
					if (!phoneField.value.trim()) {
						isIndonesian
							? showError(phoneField, "Nomor telepon diperlukan")
							: showError(phoneField, "Phone is required");
						isValid = false;
					}
					if (!companyField.value.trim()) {
						isIndonesian
							? showError(companyField, "Nama perusahaan diperlukan")
							: showError(companyField, "Company is required");
						isValid = false;
					}
					if (!messageField.value.trim()) {
						isIndonesian
							? showError(messageField, "Pesan diperlukan")
							: showError(messageField, "Message is required");
						isValid = false;
					}
					if (!checkField.checked) {
						isIndonesian
							? showError(checkField, "Anda harus menyetujui privacy policy")
							: showError(checkField, "You must agree with the privacy policy");
						isValid = false;
					}
					if (
						emailField.classList.contains("wpcf7-not-valid") ||
						phoneField.classList.contains("wpcf7-not-valid")
					) {
						isValid = false;
					}
					// Mencegah pengiriman form jika ada field yang tidak valid
					if (
						!nameField.value.trim() ||
						!emailField.value.trim() ||
						!phoneField.value.trim() ||
						!companyField.value.trim() ||
						!messageField.value.trim() ||
						!checkField.checked
					) {
						isValid = false;
						event.preventDefault();
					}
					function showError(field, message) {
						var errorElement = document.createElement("span");
						errorElement.classList.add("wpcf7-not-valid-tip");
						errorElement.innerText = message;
						field.parentNode.appendChild(errorElement);
					}
				},
				false
			);
		}
	},

	contactUsNotification: function () {
		const targetElement = document.querySelector(".wpcf7-response-output");

		if(!targetElement) return

		let observer = new MutationObserver(() => {
			const notificationElement = document.querySelector(".alert-contact-us");

			if (notificationElement) {				
				const isError = targetElement.innerHTML.includes("error");
				if (!isError) {
					notificationElement.classList.add("contact-fade-in");
					setTimeout(() => {
						notificationElement.classList.remove("contact-fade-in");
					}, 7000);
				}
			}
		});

		observer.observe(targetElement, {
			characterData: false,
			childList: true,
			attributes: false,
		});
	},

	rearrangeGSCResults: function () {
		function rearrangeGSCResults() {
			const results = document.querySelectorAll(".gsc-webResult.gsc-result");
			const noResults = document.querySelector(".gs-no-results-result");
			const resultsContainer = document.querySelector("#search-results");

			let noResultsMessageElement = null;
			if (resultsContainer) {
					noResultsMessageElement = resultsContainer.querySelector(".no-results-message");
			}

			const currentUrl = window.location.href;
			const isIndonesian = currentUrl.includes("/pencarian/");

			if (noResults) {
				if (resultsContainer && !noResultsMessageElement) {
					noResultsMessageElement = document.createElement("p");
					noResultsMessageElement.classList.add("no-results-message");
					resultsContainer.appendChild(noResultsMessageElement);
				}
				if (noResultsMessageElement) {
					noResultsMessageElement.textContent = isIndonesian
							? "Tidak ada hasil"
							: "No result";
				}
			} else if (results.length > 0) {
				results.forEach(function (result) {
					const thumbnail = result.querySelector(
						".gsc-table-cell-thumbnail.gsc-thumbnail"
					);
					const breadcrumb = result.querySelector(
						".gsc-url-top, .gsc-url-bottom"
					);
					const snippet = result.querySelector(".gs-snippet");

					const contentWrapper = document.createElement("div");
					contentWrapper.className = "custom-content-wrapper";

					if (breadcrumb) contentWrapper.appendChild(breadcrumb);
					if (snippet) contentWrapper.appendChild(snippet);

					const resultRow = document.createElement("div");
					resultRow.className = "custom-result-row";

					if (thumbnail) {
						resultRow.appendChild(thumbnail);
					}

					resultRow.appendChild(contentWrapper);

					const resultContainer = result.querySelector(".gsc-table-result");
					resultContainer.innerHTML = "";
					resultContainer.appendChild(resultRow);
				});

				if (noResultsMessageElement) {
					noResultsMessageElement.textContent = "";
				}
			}
		}

		setTimeout(rearrangeGSCResults, 1000);

		const observer = new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				if (mutation.addedNodes.length || mutation.removedNodes.length) {
					setTimeout(rearrangeGSCResults, 500);
				}
			});
		});

		const searchContainer = document.querySelector(".gsc-resultsbox-visible");
		if (searchContainer) {
			observer.observe(searchContainer, { childList: true, subtree: true });
		}

		document.addEventListener("click", function (event) {
			if (event.target.classList.contains("gsc-cursor-page")) {
				setTimeout(rearrangeGSCResults, 1000);
			}
		});
	},

	slideBtnPhoneMail: function () {
		const stickyContainerPhoneMail =
			document.querySelector(".sticky-container");

		jQuery(window).on("scroll", function () {
			if (window.innerWidth >= 767) {
				if (jQuery(this).scrollTop() > 200) {
					stickyContainerPhoneMail.style.top = "40%";
					stickyContainerPhoneMail.style.display = "block";
				} else {
					stickyContainerPhoneMail.style.top = "85%";
				}
			}

			if (window.innerWidth <= 767) {
				if (jQuery(this).scrollTop() > 200) {
					stickyContainerPhoneMail.classList.add("show");
					stickyContainerPhoneMail.classList.remove("hide");
				} else {
					stickyContainerPhoneMail.classList.add("hide");
					stickyContainerPhoneMail.classList.remove("show");
				}
			}
		});
	},

	magnifyImage: function () {
		jQuery(".image-magnify-wrapper").on("click", function () {
			var imgSrc = this.children[0].children[0].src;
			jQuery(".zoom-img-content").attr("src", imgSrc);
			jQuery(".zoom-wrap").fadeIn();
		});

		jQuery(".zoom-close").on("click", function () {
			jQuery(".zoom-wrap").fadeOut();
		});

		jQuery(".zoom-wrap").on("click", function () {
			jQuery(".zoom-wrap").fadeOut();
		});
	},

	languageDropdown: function () {
		jQuery(".selected-language").on("click", function (event) {
			event.stopPropagation();
			jQuery(".language-options").addClass("open");
			jQuery(".icon-arrow").addClass("rotate");
		});

		jQuery(document).on("click", function (event) {
			if (!jQuery(event.target).closest(".language-select").length) {
				jQuery(".language-options").removeClass("open");
				jQuery(".icon-arrow").removeClass("rotate");
			}
		});
	},

	rearrangeCheckbox: function () {
		const checkBoxes = document.getElementsByClassName("check-box");
		const textBoxes = document.getElementsByClassName("text-box");
		if (checkBoxes[0] && textBoxes[0]) {
			const labelElement = checkBoxes[0].children[0].children[0];
			labelElement.appendChild(textBoxes[0]);
		}
	},

	openRealCookieBanner: function () {
		const banner = document.getElementsByClassName("open-cookie-banner");
		if(banner[0]){
			banner[0].addEventListener("click", function() {
				function deleteAllCookies() {
					document.cookie.split(";").forEach(cookie => {
						let name = cookie.split("=")[0].trim();
						document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
						document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + window.location.hostname;
					});
				}

				if (document.location.protocol === "https:") {
					document.cookie.split(";").forEach(cookie => {
						let name = cookie.split("=")[0].trim();
						document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure";
					});
				}

				deleteAllCookies();
				localStorage.clear();
				sessionStorage.clear();

				location.reload();
			});
		}
	},
};

jQuery(document).ready(function () {
	general.init();
});
